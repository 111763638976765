$(function () {
  'use strict';

  $(document).ready(function () {
    var $buttons = $('.js-carbon-button');
    var $overlay = $('.js-carbon-overlay');
    var $closeButtons = $('.js-carbon-close');
    var emissionResult = '';
    var diagramResult = '';
    var diagramTotalResult = '';


    var ctx = $('#myChart');

    function init () {
      var $carbonButtonContainer = $('.js-carbon-button-container');
      var observer = new IntersectionObserver(
        function (entries) {
          entries.forEach(function (entry) {
            if (entry.isIntersecting) {
              $carbonButtonContainer.addClass('hide');
              console;
            } else {
              $carbonButtonContainer.removeClass('hide');
            }
          });
        },
        {
          root: null,
          threshold: 0.1,
        }
      );

      var $carbonElement = document.querySelector('.js-carbon-footer');
      if ($carbonElement) {
        observer.observe($carbonElement);
      }

      var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: [["Jardin", "d'assainissement"],["Assainissement", "des eaux usées"]],
          datasets: [{
            label: 'Emissions CO2',
            data: [0,0],
            borderWidth: 1
          }]
        },
        options: {
          maintainAspectRatio: false,
          plugins:{
            legend: {
              display: false
            },

          },
          animation: false,

          backgroundColor: ["green","grey"],
        }
      });

      $buttons.on('click', function () {
        if ($overlay.length) {
          $overlay.removeClass('none').addClass('open');
          $('html, body').animate({ scrollTop: 0 }, 'fast');
          $('html').addClass('carbon-simulator-opened');
          listenToFormChanges($overlay);
          initTooltip($overlay);
        }
      });

      $closeButtons.on('click', function () {
        if (!$overlay.hasClass('none')) {
          $overlay.addClass('none').removeClass('open');
        }
        $('html').removeClass('carbon-simulator-opened');
      });

      function listenToFormChanges($overlay) {
        var $form = $overlay.find('.js-carbon-form');
        var $resultContainer1 = $overlay.find('.js-carbon-result-1');
        var $resultContainer2 = $overlay.find('.js-carbon-result-2');
        var $resultContainer3 = $overlay.find('.js-carbon-result-3');
        var $carResultContainer = $overlay.find('.js-carbon-car');
        var $streamingResultContainer = $overlay.find('.js-carbon-streaming');
        // var $coffeeResultContainer = $overlay.find('.js-carbon-coffee');
        var $residentSelect = $form.find('.js-carbon-resident');

        function updateResidentOptions(selectedType) {
          var $options = $residentSelect.find('option');

          $options.prop('disabled', false);

          if (selectedType === 'gamme-roseaux' || selectedType === 'gamme-iris') {
            $options.each(function () {
              var value = $(this).val();
              if (['11', '13', '15', '17', '19'].includes(value)) {
                $(this).prop('disabled', true);
              }
            });
          } else if (selectedType === 'gamme-carex') {
            $options.each(function () {
              var value = $(this).val();
              if (value !== '5' && value !== '6') {
                $(this).prop('disabled', true);
              }
            });
          }

          var selectedValue = $residentSelect.val();
          if ($residentSelect.find('option:selected').prop('disabled')) {
            var firstValidOption = $residentSelect.find('option:not(:disabled)').first();
            if (firstValidOption.length > 0) {
              $residentSelect.val(firstValidOption.val());
            }
          }
        }

        if ($form.length > 0) {
          $form.find('input[name="jardin-gamme"], .js-carbon-resident, .js-carbon-years').on('change input', function () {
            var selectedType = $form.find('input[name="jardin-gamme"]:checked').val();
            var selectedResident = parseFloat($form.find('.js-carbon-resident').val());
            var carResult = '';
            var streamingResult = '';
            diagramResult = '';
            diagramTotalResult = ''
            var coffeeResult = '';

            updateResidentOptions(selectedType);

            if (!isNaN(selectedResident)) {
              var totalResult;

              switch (selectedType) {
                case 'gamme-roseaux':
                  // result = 50.7 * selectedResident;
                  var roseauxResults = {
                    2: 3777.4,
                    3: 5666.1,
                    4: 7554.8,
                    5: 9443.5,
                    6: 11332.2,
                    7: 13220.9,
                    8: 15109.6,
                    9: 16998.3,
                    10: 18887.0,
                    12: 22664.4,
                    14: 26441.8,
                    16: 30219.2,
                    18: 33996.5,
                    20: 37773.9,
                  };
                  totalResult = roseauxResults[selectedResident];

                  var roseauxCarResults = {
                    2: 16352,
                    3: 24529,
                    4: 32705,
                    5: 40881,
                    6: 49057,
                    7: 57233,
                    8: 65409,
                    9: 73586,
                    10: 81762,
                    12: 98114,
                    14: 114466,
                    16: 130819,
                    18: 147171,
                    20: 163524,
                  };
                  carResult = roseauxCarResults[selectedResident];
                  var roseauxStreamingResults = {
                    2: 20986,
                    3: 31478,
                    4: 41971,
                    5: 52464,
                    6: 62957,
                    7: 73449,
                    8: 83942,
                    9: 94435,
                    10: 104928,
                    12: 125913,
                    14: 146899,
                    16: 167884,
                    18: 188870,
                    20: 209855,
                  };
                  streamingResult = roseauxStreamingResults[selectedResident];
                  var roseauxDiagramResults = {
                    2: -101,
                    3: -152,
                    4: -203,
                    5: -253,
                    6: -304,
                    7: -355,
                    8: -406,
                    9: -456,
                    10: -507,
                    12: -608,
                    14: -710,
                    16: -811,
                    18: -913,
                    20: -1014,
                  };
                  diagramResult = roseauxDiagramResults[selectedResident];
                  break;
                case 'gamme-iris':
                  // result = 101.4 * selectedResident;
                  var irisResults = {
                    2: 3878.8,
                    3: 5818.2,
                    4: 7757.6,
                    5: 9697.0,
                    6: 11636.4,
                    7: 13575.8,
                    8: 15515.2,
                    9: 17454.5,
                    10: 19393.9,
                    12: 23272.7,
                    14: 27151.5,
                    16: 31030.3,
                    18: 34909.1,
                    20: 38787.9,
                  };
                  totalResult = irisResults[selectedResident];

                  var irisCarResults = {
                    2: 16791,
                    3: 25187,
                    4: 33583,
                    5: 41978,
                    6: 50374,
                    7: 58770,
                    8: 67165,
                    9: 75561,
                    10: 83956,
                    12: 100748,
                    14: 117539,
                    16: 134330,
                    18: 151122,
                    20: 167913,
                  };
                  carResult = irisCarResults[selectedResident];
                  var irisStreamingResults = {
                    2: 21549,
                    3: 32323,
                    4: 43098,
                    5: 53872,
                    6: 64646,
                    7: 75421,
                    8: 86195,
                    9: 96970,
                    10: 107744,
                    12: 129293,
                    14: 150842,
                    16: 172391,
                    18: 193939,
                    20: 215488,
                  };
                  streamingResult = irisStreamingResults[selectedResident];

                  var irisDiagramResults = {
                    2: -203,
                    3: -304,
                    4: -406,
                    5: -507,
                    6: -608,
                    7: -710,
                    8: -811,
                    9: -913,
                    10: -1014,
                    12: -1217,
                    14: -1420,
                    16: -1622,
                    18: -1622,
                    20: -1622,
                  };
                  diagramResult = irisDiagramResults[selectedResident];
                  break;
                case 'gamme-carex':
                  // result = 25.4 * selectedResident - 0.3;
                  var carexResults = {
                    5: 9316.7,
                    6: 11180.1,
                  };
                  totalResult = carexResults[selectedResident];

                  var carexCarResults = {
                    5: 40332,
                    6: 48399,
                  };
                  carResult = carexCarResults[selectedResident];
                  var carexStreamingResults = {
                    5: 51760,
                    6: 62112,
                  };
                  streamingResult = carexStreamingResults[selectedResident];

                  var carexDiagramResults = {
                    5: -127,
                    6: -152,
                  };
                  diagramResult = carexDiagramResults[selectedResident];
                  break;
                default:
                  return;
              }

              emissionResult = totalResult;

              $carResultContainer.text(numberToText(carResult, true));

              $streamingResultContainer.text(numberToText(streamingResult, true));

              var classiqueDiagramResults = {
                2: 3676,
                3: 5514,
                4: 7352,
                5: 9190,
                6: 11028,
                7: 12866,
                8: 14704,
                9: 16542,
                10: 18380,
                12: 22056,
                14: 25732,
                16: 29408,
                18: 33084,
                20: 36760,
              };

              diagramTotalResult = classiqueDiagramResults[selectedResident];

              $resultContainer1.text(numberToText(diagramResult * (-1)));
              $resultContainer2.text(numberToText(diagramTotalResult));
              $resultContainer3.text(numberToText(parseFloat(totalResult).toFixed(1)));
              $resultContainer3.text(numberToText(Number.isInteger(totalResult) ? totalResult : totalResult.toFixed(1)));


              // update diagramm
              myChart.data.datasets[0].data[0] = diagramResult;
              myChart.data.datasets[0].data[1] = diagramTotalResult;
              myChart.options.scales.y.max = diagramTotalResult;
              myChart.options.scales.y.min = diagramResult;
              myChart.update();


            } else {
              console.warn('Valeurs du formulaire invalides.');
            }
          });
        }
      }

      function numberToText(number, specialRound) {
        if (specialRound) {
          number = Math.round(number / 500) * 500;
        }
        else {
          number = Math.round(number);
        }
        number = number.toString();

        var x = number.split('.');
        var x1 = x[0];
        var x2 = x.length > 1 ? ',' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ' ' + '$2');
        }
        return x1 + x2;
      }

      function initTooltip($overlay) {
        var $tooltipBtn = $overlay.find('.carbon-tooltip-btn');
        var $tooltip = $overlay.find('.carbon-tooltip');
        var $tooltipClose = $overlay.find('.carbon-tooltip-close');

        $tooltipBtn.on('click', function () {
          $tooltip.toggleClass('none');
        });

        $tooltipClose.on('click', function () {
          $tooltip.addClass('none');
        });
      }
    }

    if (ctx) {
      var script = document.createElement('script');
      script.onload = function () {
        init();
      };

      script.src = 'https://cdn.jsdelivr.net/npm/chart.js';

      document.head.appendChild(script);
    }
  });
});
